import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import PageHeader from '../../components/page-header';
import PageFooter from '../../components/page-footer';
import PageLayout from '../../components/page-layout';
import { getMarkdownPageNode } from '../../utils';
import './index.scss';

const AboutPage = ({ data }) => {
  const page = getMarkdownPageNode(data);
  if (!page) {
    return null;
  }
  return (
    <PageLayout className="AboutPage">
      <Helmet>
        <title>{`Firstness Journal · ${page.frontmatter.title}`}</title>
      </Helmet>
      <PageHeader />
      <main>
        <h1>About</h1>
        <div className="markdown" dangerouslySetInnerHTML={{ __html: page.html }} />
      </main>
      <PageFooter />
    </PageLayout>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPageQuery {
    allMarkdownRemark(
      limit: 1,
      filter: {
        frontmatter: {
          type: { eq: "about-page" }
        }
      }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
